import { createStyles } from '@material-ui/styles';
import { Theme } from '../../common/theme/default';

export const styles = (theme: Theme) => createStyles({
    faqsStyle: {
        borderTop: '1px solid #cccccc',
        borderBottom: '1px solid #cccccc',
        marginBottom: '8px',
        '& button': {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            width: '100%',
            border: 0,
            backgroundColor: 'transparent',
            padding: '20px 48px 20px 25px',
            color: '#018AA2',
            fontSize: '16px',
            fontWeight: '400',
            letterSpacing: 0,
            lineHeight: '20px',
            textAlign: 'left',
            '&:focus': {
                outline: '1px auto',
            },
            "&:hover":{
                textDecoration:"underline"
            }
        },
        '& svg': {
            position: 'absolute',
            right: '25px',
            top: '22px',
            height: '16px',
            width: '16px',
        },
        '& p': {
            display: 'flex',
            whiteSpace: 'pre-line',
            color: '#FFFFFF',
            fontSize: '16px',
            letterSpacing: 0,
            lineHeight: '24px',
            padding: '0 25px 20px 25px',
            margin: '0 !important',
        }
    }
})