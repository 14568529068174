import React from "react";
import { WithStyles, withStyles } from "@material-ui/styles";
import { withStrings, WithStrings } from "components/locale";
import { styles } from "./container.styles";
import { Container, Col, Row } from "reactstrap";
import FaqsItems from "../faqs-comp/faqs-items";

interface Props extends WithStyles<typeof styles>, WithStrings {
  location: Location;
}
interface State {}

class FaqsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.heroContainer}>
          <Container>
            <Row>
              <Col lg={8} xs={12}>
                <h1>{"FAQ & Questions to Ask"}</h1>
                <p>
                  {
                    "This information is provided by an independent, nonprofit organization called CISCRP that is dedicated to educating patients and the public about clinical research."
                  }
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className={classes.faqsContainer}>
          <Row>
            <Col xs={12}>
              <h2>{"FAQ"}</h2>
            </Col>
            <Col xs={12}>
              <p>
                {
                  "Choosing whether or not to participate in a clinical trial is a decision that millions of people make each year. Before making that decision, it is important to learn about clinical trials and what it means to be a study participant. The frequently asked questions below help you learn about and what to consider before taking part in a trial:"
                }
              </p>
            </Col>
            <Col lg={9}>
              <FaqsItems />
            </Col>
            <Col xs={12}>
              <p style={{ marginBottom: "8px" }}>
                For more information about Expanded Access in the USA, please
                visit{" "}
                <a
                  href="https://www.fda.gov/news-events/public-health-focus/expanded-access"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FDA’s educational page
                </a>
                . For European readers please visit{" "}
                <a
                  href="https://www.ema.europa.eu/en/human-regulatory/research-development/compassionate-use"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  EMA’s page.
                </a>
              </p>
              <p style={{ marginTop: 0, marginBottom: "16px" }}>
                The above information is provided by clinicaltrials.gov, a
                service of the National Institute of Health. Additional
                resources for information and frequently asked questions about
                clinical trials for patients and their families can be found at:
              </p>
              <div className={"links-sec"}>
                <div>
                  <a
                    href="https://www.clinicaltrials.gov/ct2/about-studies/learn/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.clinicaltrials.gov/ct2/about-studies/learn
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.webmd.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.webmd.com
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.centerwatch.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.centerwatch.com
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.cancer.gov"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.cancer.gov
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.rarediseases.info.nih.gov"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    rarediseases.info.nih.gov
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.acrpnet.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.acrpnet.org
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.michaeljfox.org/trial-finder"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.foxtrialfinder.org
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <h2>{"Questions to Ask"}</h2>
              <p style={{ marginBottom: "8px" }}>
                People who are in a clinical trial or are thinking about joining
                a clinical trial have the right to ask the study staff any
                questions they may have about the study.
              </p>
              <p style={{ marginTop: 0, marginBottom: "8px" }}>
                The questions below are useful for patients, potential study
                volunteers, and their family members or friends, to ask the
                research staff – which can include doctors, nurses, study
                coordinators and other people who help conduct the trial.
              </p>
              <p style={{ marginTop: 0 }}>
                For a printable version of these questions,{" "}
                <a
                  href="https://www.ciscrp.org/wp-content/uploads/2019/08/Questions-to-Ask.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  click here to download the PDF
                </a>{" "}
                and take with you to your appointment.
              </p>
              <ul>
                <li>{"What is the main purpose of this study?"}</li>
                <li>
                  {
                    "Does the study involve a placebo or a treatment that is already on the market?"
                  }
                </li>
                <li>{"How will the treatment be given to me?"}</li>
                <li>
                  {
                    "How long is the study going to last and what will I be asked to do as a participant?"
                  }
                </li>
                <li>
                  {
                    "What has been learned about the study treatment and are any study results published?"
                  }
                </li>
                <li>
                  {
                    "Do I have to pay for any part of the study? Will my insurance cover these costs?"
                  }
                </li>
                <li>
                  {"Is there any reimbursement for travel costs or childcare?"}
                </li>
                <li>{"Will I be able to see my own doctor?"}</li>
                <li>
                  {
                    "If the treatment works for me, can I keep using it after the study?"
                  }
                </li>
                <li>
                  {
                    "Can anyone find out whether I’m participating in the clinical trial?"
                  }
                </li>
                <li>
                  {
                    "Will I receive any follow-up care after the study has ended?"
                  }
                </li>
                <li>
                  {
                    "What will happen to my medical care if I stop participating in the study?"
                  }
                </li>
                <li>
                  {
                    "Does the physician/investigator have any financial or special interest in the clinical study?"
                  }
                </li>
                <li>
                  {
                    "What are the credentials and research experience of the physician and study staff?"
                  }
                </li>
                <li>
                  {
                    "When and how will the results of the clinical trial be provided to me?"
                  }
                </li>
                <li>{"Will I learn my treatment assignment?"}</li>
              </ul>
            </Col>
            <Col xs={12} className={classes.learnMore}>
              <p>
                <strong>
                  You can learn more about clinical trials by visiting{" "}
                  <a
                    href="https://www.ciscrp.org/education-center/resources/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CISCRP’s Resources webpage
                  </a>
                  .
                </strong>{" "}
                There you will find brochures, videos, podcasts and other
                resources to help you make an informed decision about
                participating in clinical research. Some of the resources are
                linked below:
              </p>
              <ul>
                <li>
                  Brochures:
                  <ul>
                    <li>
                      <a
                        href="https://www.ciscrp.org/should-i-participate/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Should I Participate?
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.ciscrp.org/should-my-child-participate/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Should My Child Participate?
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.ciscrp.org/los-hispanos-y-la-investigacion-clinica/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Los Hispanos y la Investigacion Clinica
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.ciscrp.org/african-americans-and-clinical-research/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        African Americans and Clinical Research
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.ciscrp.org/debunking-common-myths-about-clinical-trials/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Debunking Common Myths About Clinical Trials
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Videos:
                  <ul>
                    <li>
                      <a
                        href="https://www.ciscrp.org/nuestro-mundo-y-los-ensayos-clinicos/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Nuestro Mundo y los Ensayos Clínicos
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.ciscrp.org/the-impact-clinical-trials-have-on-all-of-us/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        The Impact Clinical Trials Have on All of Us
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Podcast:
                  <ul>
                    <li>
                      <a
                        href="https://www.ciscrp.org/gift-of-participation-making-informed-decisions-about-volunteering-for-a-clinical-trial/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Gift of Participation: Making Informed Decisions About
                        Volunteering for a Clinical Trial
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </>
    );
  };
}

export default withStyles(styles)(withStrings(FaqsPage));
