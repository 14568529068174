import { createStyles } from "@material-ui/styles";
import { Theme } from "components/common/theme/default";

export const styles = (theme: Theme) =>
  createStyles({
    heroContainer: {
      background: theme.color.background,
      color: theme.color.text,
      backgroundPosition: "center, right bottom",
      backgroundSize: "cover",
      display: "flex",
      alignItems: "flex-end",
      borderBottom: "8px solid white",
      paddingTop: "75px",
      "& h1": {
        color: "white",
        margin: "60px 0 0 0",
        fontWeight: 300,
        whiteSpace: "nowrap",
        fontSize: "50px",
        lineHeight: "64px",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
          fontSize: "32px",
          lineHeight: "40px",
          whiteSpace: "pre-wrap",
          margin: "20px 0 20px 0",
        },
        [theme.breakpoints.down("xs")]: {
          margin: "20px 0 20px 0",
        },
      },
      "& p": {
        color: "#FFFFFF",
        fontSize: "16px",
        letterSpacing: 0,
        lineHeight: "24px",
        marginBottom: "80px",
        marginTop: "8px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "40px",
        },
      },
    },
    faqsContainer: {
      "& h2": {
        color: "#018AA2",
        fontSize: "28x",
        lineHeight: "34px",
        fontWeight: "300",
        marginTop: "32px",
        [theme.breakpoints.down("md")]: {
          fontSize: "32px",
          lineHeight: "1.2 ",
        },
      },
      "& .links-sec": {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "16px",
      },
      "& ul": {
        paddingInlineStart: "20px",
        color: "#4D4D4D",
        marginBottom: "50px",
        "& li": {
          marginBottom: "6px",
        },
      },
      "& h3": {
        color: "#018AA2",
        fontSize: "28px",
        lineHeight: "32px",
        fontWeight: "400",
        marginBottom: "27px",
        [theme.breakpoints.down("md")]: {
          fontSize: "24px",
          lineHeight: "1.2 !important",
        },
      },
      "& h4": {
        color: "#018AA2",
        fontSize: "21px",
        lineHeight: "24px",
        fontWeight: "300",
      },
      "& p": {
        color: "#333333",
        marginTop: "24px",
        marginBottom: "32px",
      },
      "& a": {
        color: "#00ACD9",
        textDecoration: "none",
        fontWeight: "300",
        borderBottom: "1px solid",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "& img": {
        width: "100%",
        marginBottom: "32px",
      },
    },
    symptomsContainer: {
      display: "flex",
      "& div": {
        width: "30%",
        height: "64px",
        borderRadius: "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& p": {
          color: "white",
          margin: "0 !important",
          padding: "0 20px",
          fontWeight: 300,
          fontSize: "21px",
          lineHeight: "24px",
          [theme.breakpoints.down("md")]: {
            fontSize: "18px",
          },
        },
      },
      "& div:nth-child(1), div:nth-child(2)": {
        marginRight: "5%",
      },
      "&.mild > div": {
        backgroundColor: "#E5C316",
      },
      "&.severe > div": {
        backgroundColor: "#DE2929",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        "& div": {
          width: "100%",
          margin: "0 0 10px 0 !important",
        },
      },
    },
    title: {
      marginTop: "64px !important",
      lineHeight: "40px !important",
      [theme.breakpoints.down("md")]: {
        marginTop: "32px !important",
      },
    },
    casesSection: {
      "& > div:first-child": {
        borderRight: "1px solid #E5E5E5",
      },
    },
    alignCenter: {
      textAlign: "center",
      "& h3": {
        textTransform: "capitalize",
        fontWeight: 300,
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    },
    source: {
      whiteSpace: "pre-line",
      fontWeight: "bold",
      marginBottom: "48px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "24px",
      },
    },
    videoSection: {
      "& h3": {
        textAlign: "left",
        whiteSpace: "pre-line",
      },
      "& p:nth-child(2)": {
        fontSize: "21px",
        lineHeight: "25px",
      },
      "& .playerYouTube": {
        height: "420px",
        marginBottom: "40px",
        "& > iframe": {
          borderRadius: "6px",
          boxShadow:
            "0 2px 4px 0 rgba(0,0,0,0.05), 0 10px 20px 0 rgba(0,0,0,0.1)",
        },
        [theme.breakpoints.down("sm")]: {
          height: "350px",
          marginBottom: "24px",
        },
      },
    },
    banner: {
      width: "100%",
      background: "linear-gradient(225deg, #453798 0%, #018AA2 100%)",
      marginTop: "64px",
      position: "relative",
      "& > img": {
        height: "100%",
        width: "auto",
        position: "absolute",
        right: "100px",
        opacity: 0.3,
      },
      "& h3": {
        color: "white",
        textAlign: "left",
        lineHeight: "34px",
        marginTop: "48px",
        marginBottom: "48px",
        fontWeight: 300,
      },
      "& h4": {
        color: "white",
        textAlign: "left",
        marginBottom: "40px",
        "& b": {
          fontWeight: "bold",
        },
      },
      "& p": {
        color: "white",
        textAlign: "left",
        fontSize: "14px",
        lineHeight: "24px",
        marginTop: "40px",
        marginBottom: "32px",
      },
      "& a": {
        "& img": {
          height: "24px",
          width: "auto",
          margin: "0 0 0 10px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "0",
      },
    },
    resourceLink: {
      color: "white !important",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "12px 35px",
      border: "1px solid white",
      borderRadius: "4px",
      textTransform: "uppercase",
      textDecoration: "none !important",
      "&:hover": {
        opacity: 0.7,
      },
    },
    infoLinks: {
      "& a": {
        color: "#00ACD9 !important",
        fontSize: "14px",
        lineHeight: "16px",
        padding: "12px 35px",
        border: "1px solid #00ACD9",
        borderRadius: "4px",
        textTransform: "uppercase",
        textDecoration: "none !important",
        marginRight: "15px",
        "&:hover": {
          opacity: 0.7,
        },
      },
      [theme.breakpoints.down("lg")]: {
        display: "flex",
        flexWrap: "wrap",
        "& a": {
          width: "48%",
          marginBottom: "10px",
          textAlign: "center",
          marginRight: "0",
        },
        "& a:nth-child(odd)": {
          marginRight: "4%",
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        "& a": {
          width: "100%",
          marginRight: "0px",
          marginBottom: "10px",
        },
        "& a:last-child": {
          marginBottom: "30px",
        },
      },
    },
    learnMore: {
      "& p": {
        marginBottom: "15px",
        marginTop: "0",
      },
      "& ul": {
        "& li": {
          "& ul": {
            marginBottom: "15px",
          },
        },
      },
    },
  });
