import * as React from 'react';
import Layout from '../components/layout/layout';
import Container from 'components/faqs/container/container';

export default ({ location }: { location: Location }) => {
    return (
        <Layout location={location} title={'Education Center for Frequently Asked Questions and Questions to ask the research staff'} description={'The  Frequently Asked Questions help you to learn about clinical trials and what it means to be a study participant. The questions to ask are useful for patients, potential study volunteers, and their family members or friends, to ask the research staff – which can include doctors, nurses, study coordinators and other people who help conduct the trial.'} tenantCss={'resultsCssUrl'}>
            <Container location={location} />
        </Layout>
    );
};