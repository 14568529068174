import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from './faqs-items.style';
import { withStrings, WithStrings } from '../../locale';
import React, { createRef } from 'react';
import { Collapse } from 'reactstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';

interface Props extends WithStyles<typeof styles>, WithStrings {
}

interface State {
    collapse: Array<boolean>;
}

class FaqsItems extends React.Component<Props, State> {
    formRef = createRef<HTMLFormElement>();

    constructor(props: Props) {
        super(props);
        this.state = {
            collapse: this.props.strings.faqsPage.faqsItems.map(() => {
                return false;
              }),
        };
    }

    render = () => {
        const { strings, classes } = this.props;
        return (
               strings.faqsPage.faqsItems.map((item, index) => (
                   <div key={index} className={classes.faqsStyle}>
                       <button onClick={()=> this.toggle(index)}>{item.title} {this.state.collapse[index] ? <FaMinus /> : <FaPlus />}</button>
                        <Collapse isOpen={this.state.collapse[index]}><p>{item.description}</p></Collapse>
                   </div>
               ))
        );
    };

    private toggle = (itemIndex: number) => {
            let collapse = this.state.collapse;
            this.setState({
              collapse: collapse.map((value, index) =>
                index !== itemIndex ? false : !value
              ),
            });
          };
    };

export default withStyles(styles)(withStrings(FaqsItems));
